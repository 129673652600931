import type { Theme as ThemeBase } from 'styled-system';

type Breakpoints = Record<0 | 1 | 2 | 'sm' | 'md' | 'lg', string>;

const breakpointValues = ['768px', '992px', '1200px'];
const breakpoints: Breakpoints = breakpointValues as any;
// xs breakpoint sets styles for all screen widths -
// using a breakpoint definition without an alias sets
// the style as a base value, outside of a media query
// https://styled-system.com/responsive-styles/#using-objects
[breakpoints.sm, breakpoints.md, breakpoints.lg] = breakpointValues;

const colors = {
  black: '#363135',
  white: '#fff',
  blue: {
    fb: '#37639e',
    twitter: '#23a2f0',
    vk: '#4d75a3',
  },
  gray: {
    light: '#dbd9db',
    lightTranslucent: 'hsla(305, 6%, 22%, 0.185)',
    medium: '#adacad',
    mediumTranslucent: 'hsla(305, 1%, 61%, 0.83)',
    dark: '#737273',
  },
  green: {
    1: '#497143',
    2: '#63985a',
    3: '#bbd6b2',
    4: '#e2eee0',
    5: '#bbd6b2', // deprecate this in favour of green.3 (duplicate)
    'line-app': '#00c300',
    whatsapp: '#48c258',
    teal: '#3eca8a',
  },
  teal: {
    1: '#2da06c',
    2: '#b1dec9',
  },
  red: {
    brand: '#ec2c22',
    1: '#b32c0e',
  },
  yellow: {
    1: '#b48629',
    2: '#dab65d',
    3: '#ffe9aa',
    4: '#fff9df',
    5: '#fdf9f2',
    ish: '#fffcf7',
    ishDark: '#ffedc9',
  },
  karl: {
    theFog: '#f6f4f6',
    translucent: 'hsla(305, 11%, 23%, 0.05)',
  },
  button: {},
};

// prettier-ignore
type ColorNames = 'black' |
  'white' |
  'blue.fb' | 'blue.twitter' | 'blue.vk' |
  'gray.light' | 'gray.lightTranslucent' | 'gray.medium' | 'gray.mediumTranslucent' | 'gray.dark' |
  'green.1' | 'green.2' | 'green.3' | 'green.4' | 'green.5' | 'green.line-app' | 'green.whatsapp' | 'green.teal' |
  'teal.1' | 'teal.2' |
  'red.1' | 'red.brand' |
  'yellow.1' | 'yellow.2' | 'yellow.3' | 'yellow.4' | 'yellow.5' | 'yellow.ish' | 'yellow.ishDark' |
  'karl.theFog' | 'karl.translucent'
  ;

const fontSizes = {
  xs: `${Math.pow(1.25, -2)}rem`, // 10.24px
  s: `${Math.pow(1.25, -1)}rem`, // 12.8px
  m: '1rem', // 16px
  l: `${Math.pow(1.25, 1)}rem`, // 20px
  xl: `${Math.pow(1.25, 5)}rem`, // 48.8281px
  xxl: `${Math.pow(1.25, 8)}rem`, // 95.3674px
  'mobile-l': `${Math.pow(1.25, 1) * 0.9}rem`, // 18px
  'mobile-xl': `${Math.pow(1.25, 5) * 0.8}rem`, // 39.0625px
  'mobile-xxl': `${Math.pow(1.25, 8) * 0.8}rem`, // 76.2939px
};

const radii: [number | string, number | string, number | string] = [0, '5px', '10000rem'];

const space = {
  n: 0,
  xxxs: 3,
  xxs: 5,
  xs: 10,
  s: 15,
  m: 20,
  l: 25,
  xl: 30,
  xxl: 40,
  xxxl: 60,
};

const transitionDuration = {
  fast: '0.1s',
  normal: '0.2s',
  slow: '0.3s',
};

const borderWidths: [number, string, string] = [0, '1px', '2px'];

const shadows: [string, string] = ['none', '0 2px 3px 0 rgba(54, 49, 53, 0.2)'];

const zIndices = {
  pullForward: 1000,
  positionSticky: 2000,
  dropdown: 3000,
  offscreen: 4000,
  modal: 5000,
  tooltip: 6000,
  loading: 7000,
};

type Full<T> = {
  [P in keyof T]-?: T[P];
};

// types for the Theme type are a bit specific to have proper type resolution with the styled-system
export type Theme = Full<Omit<ThemeBase, 'breakpoints' | 'space' | 'colors' | 'fontSizes' | 'zIndices'>> & {
  breakpoints: Record<'xs' | 'sm' | 'md' | 'lg', string>;
  colors: Record<ColorNames, string>;
  fontSizes: typeof fontSizes;
  space: typeof space;
  transitionDuration: typeof transitionDuration;
  zIndices: typeof zIndices;
};

const theme = {
  breakpoints,
  colors,
  fontSizes,
  borderWidths,
  radii,
  shadows,
  space,
  transitionDuration,
  zIndices,
};

// eslint-disable-next-line import/no-default-export
export default theme;
