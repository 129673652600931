import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

import theme from './theme';

const baseFontFamily = '"Helvetica Neue", Helvetica, Arial, Tahoma, sans-serif';

// exporting the style to be able to insert it in the generated HTML
// to avoid font flickering in dev mode with cache disabled
// https://github.com/styled-components/styled-components/issues/1593
// (left in GlobalStyle for now for backward compatibility)
export const webfontsStyle = `
  @font-face {
    font-family: "Change Calibre";
    src: url("https://static.change.org/fonts/Change-Calibre-Regular.woff2") format("woff2"),
      url("https://static.change.org/fonts/Change-Calibre-Regular.woff") format("woff"),
      url("https://static.change.org/fonts/Change-Calibre-Regular.ttf.gz") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Change Calibre";
    src: url("https://static.change.org/fonts/Change-Calibre-Bold.woff2") format("woff2"),
      url("https://static.change.org/fonts/Change-Calibre-Bold.woff") format("woff"),
      url("https://static.change.org/fonts/Change-Calibre-Bold.ttf.gz") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
`;

const GlobalStyle = createGlobalStyle`
  ${normalize}

  /*
  Box model
  */
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  /*
  Webfonts
  */
  ${webfontsStyle}

  /*
  Default typography
  */
  body {
  font-family: ${baseFontFamily};
  font-size: 16px;
  line-height: 1.5;
  color: ${theme.colors.black};
}

/* Set type to Calibre once font is loaded */
.type-loaded {
  font-family: Change Calibre, ${baseFontFamily};
}

/*
Default list styles
*/
ol,
ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

/*
Default anchor styles
*/
a {
  color: inherit;
  cursor: pointer;
  text-decoration: underline;

  &:active: {
    outline: none;
  }
}

/*
Default button styles
*/
button {
  padding: 0;
  background: none;
  border: 0;
  outline: 0;
}

/*
Paragraphs

Normalize margins for paragraphs. This allows you to use the <p> tag
freely without having to apply spacing overrides for margin.
*/
p {
  margin: 0;
}
`;

export { GlobalStyle };
