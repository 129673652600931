import React from 'react';

import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

import { GlobalStyle } from '../GlobalStyle';
import theme from '../theme';

// eslint-disable-next-line @typescript-eslint/ban-types
function Wrapper({ children }: React.PropsWithChildren<{}>): JSX.Element {
  return (
    <div className="type-loaded">
      <GlobalStyle />
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </div>
  );
}

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

// eslint-disable-next-line import/no-default-export
export default Wrapper;
