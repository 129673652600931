export const imports = {
  'AnimatePulse.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "animate-pulse" */ 'AnimatePulse.mdx'
    ),
  'AnimateZoom.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "animate-zoom" */ 'AnimateZoom.mdx'
    ),
  'AspectRatio.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "aspect-ratio" */ 'AspectRatio.mdx'
    ),
  'BorderBox.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "border-box" */ 'BorderBox.mdx'
    ),
  'Box.mdx': () =>
    import(/* webpackPrefetch: true, webpackChunkName: "box" */ 'Box.mdx'),
  'AspectRatioCoverImage.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "aspect-ratio-cover-image" */ 'AspectRatioCoverImage.mdx'
    ),
  'Bubble.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "bubble" */ 'Bubble.mdx'
    ),
  'Button.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "button" */ 'Button.mdx'
    ),
  'Dropdown.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "dropdown" */ 'Dropdown.mdx'
    ),
  'Ellipsis.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "ellipsis" */ 'Ellipsis.mdx'
    ),
  'Icons.mdx': () =>
    import(/* webpackPrefetch: true, webpackChunkName: "icons" */ 'Icons.mdx'),
  'Input.mdx': () =>
    import(/* webpackPrefetch: true, webpackChunkName: "input" */ 'Input.mdx'),
  'Leaders.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "leaders" */ 'Leaders.mdx'
    ),
  'ModalDialog.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "modal-dialog" */ 'ModalDialog.mdx'
    ),
  'Spinner.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "spinner" */ 'Spinner.mdx'
    ),
  'Text.mdx': () =>
    import(/* webpackPrefetch: true, webpackChunkName: "text" */ 'Text.mdx'),
  'Tooltip.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "tooltip" */ 'Tooltip.mdx'
    ),
  'docs/DesignGuidelines.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-design-guidelines" */ 'docs/DesignGuidelines.mdx'
    ),
  'docs/ResponsiveProps.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-responsive-props" */ 'docs/ResponsiveProps.mdx'
    ),
  'docs/Theme.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-theme" */ 'docs/Theme.mdx'
    ),
  'docs/Overview.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-overview" */ 'docs/Overview.mdx'
    ),
}
